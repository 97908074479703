

.App {
    text-align: center;
}

* {
    box-sizing: border-box;
}

*:before,
*:after {
    box-sizing: border-box;
}

html,
.Site {
    height: 100%;
    position: relative;
}

.site-header-padder {
    height: calc((100vw - 80em) / 2);
    max-height: 4em;
}

.site-footer-padder {
    height: calc((80em - 100vw) / 2 + 4em);
    max-height: 4em;
}

.site-content {
    min-height: calc(100vh - 64px - 50px - 69px - 10px); /* will cover the 100% of viewport */
    max-width: 80em;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 69px; /* height of your footer */
}

